const RandomNumber = ({
  min,
  max,
  count,
  randomNumber,
  rollNumber,
  setMin,
  setMax,
  setCount,
  checked,
  setChecked,
}) => {
  const changeRandomNum = () => {
    for (let i = 0; i < 6; i++) {
      setTimeout(() => {
        rollNumber(); // Выполняем бросок
      }, i * 80); // Умножаем i на 1000, чтобы получить 1-секундные интервалы
    }
  };

  function chengeCheckbox() {
    if (Math.abs(Math.abs(max) - Math.abs(min)) < count - 1) setChecked(false);
    else setChecked(!checked);
  }

  return (
    <div>
      <div className="tex">
        <p>
          Добавьте немного неожиданности в свою жизнь с помощью генератора
          случайных чисел! 🎲✨
          <br />
          <br />
          С нашим генератором вы можете создавать случайные числа в диапазоне от
          -1 000 000 до 1 000 000 🔢
          <br />
          Хотите получить одно число для вдохновения или целую сотню для
          азартной игры? 🎰
          <br />
          <br />
          Выберите свои параметры ⚙, и пусть удача решит, что вам выпадет! 🍀💫
          <br />
        </p>
      </div>

      <div>
        <div className="setting">
          <p>Диапазон:</p>
          <input
            type="text"
            min="-1000000"
            max="1000000"
            value={min.toString().replace(/^0+/, "")} // убираем начальные нули при отображении
            placeholder={min}
            onChange={(e) => {
              const rawValue = e.target.value;

              // Проверяем, является ли введенное значение числом
              if (/^-?\d*$/.test(rawValue)) {
                const value = rawValue === "" ? 0 : Number(rawValue);
                const formattedValue = value; // Оставляем только значение без начальных нулей

                if (value >= -1000000 && value <= 1000000) {
                  setMin(formattedValue); // обновляем min
                  if (
                    Math.abs(Math.abs(max) - Math.abs(formattedValue)) <
                    count - 1
                  )
                    setChecked(false);
                }
              }
            }}
          />
          <p>-</p>
          <input
            type="text"
            min="-1000000"
            max="1000000"
            value={max.toString().replace(/^0+/, "")} // убираем начальные нули при отображении
            placeholder={max}
            onChange={(e) => {
              const rawValue = e.target.value;

              // Проверяем, является ли введенное значение числом
              if (/^-?\d*$/.test(rawValue)) {
                const value = rawValue === "" ? 0 : Number(rawValue);
                const formattedValue = value; // Оставляем только значение без начальных нулей

                if (value >= -1000000 && value <= 1000000) {
                  setMax(formattedValue); // обновляем min
                  if (
                    Math.abs(Math.abs(formattedValue) - Math.abs(min)) <
                    count - 1
                  )
                    setChecked(false);
                }
              }
            }}
          />
        </div>

        <div className="setting">
          <p>Количество чисел:</p>
          <input
            type="text"
            min="1"
            max="100"
            placeholder={count}
            value={count.toString().replace(/^0+/, "")}
            onChange={(e) => {
              const rawValue = e.target.value;
              // Проверяем, является ли введенное значение числом
              if (/^-?\d*$/.test(rawValue)) {
                const value = rawValue === "" ? 0 : Number(rawValue);
                const formattedValue = value; // Оставляем только значение без начальных нулей

                if (value >= 1 && value <= 100) {
                  setCount(formattedValue); // обновляем Count
                  if (
                    Math.abs(Math.abs(max) - Math.abs(min)) <
                    formattedValue - 1
                  )
                    setChecked(false);
                }
              }
            }}
          />
        </div>

        <div className="setting">
          <p>Без повтора:</p>
          <input
            className="setting-checkbox"
            type="checkbox"
            checked={checked}
            onChange={chengeCheckbox}
          />
        </div>
      </div>

      <p>
        <br />
        Нажмите кнопку 🔢, чтобы сгенерировать случайные числа ➡️
        <br />
      </p>

      <button className="shuffleButton" onClick={changeRandomNum}>
        1 2 <br />3 4
      </button>

      <h1>Случайное значение:</h1>
      <div className="random">
        {randomNumber.map((num, index) => {
          let sizeClass = "";

          if (Math.abs(num) < 10000) {
            sizeClass = "number-size";
          } else if (Math.abs(num) >= 10000 && Math.abs(num) < 100000) {
            sizeClass = "number-size-10000";
          } else if (Math.abs(num) >= 100000 && Math.abs(num) < 1000000) {
            sizeClass = "number-size-100000";
          } else if (Math.abs(num) >= 1000000) {
            sizeClass = "number-size-1000000";
          }
          return (
            <div key={index} className={`number ${sizeClass}`}>
              {num}
            </div>
          ); // Добавлен ключ для каждого элемента
        })}
      </div>

      <p>
        <br />
      </p>
    </div>
  );
};

export default RandomNumber;
