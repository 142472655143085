const RandomCoin = ({
  rollCoin,
  previousResults,
  attempts,
  rotation,
  isHeads,
}) => {
  const rotationStyle = { transform: `rotateY(${rotation}deg)` };

  const changeRandomNum = () => {
    rollCoin(); // Выполняем бросок монеты
  };

  return (
    <div>
      <div className="random">
        <div className="tex">
          <p>
            Игра «Орел и решка» - это классический способ испытать удачу! 🍀
            <br /> <br />
            При броске монеты у вас есть два возможных результата: орел или
            решка. <br /> При каждом броске вероятность каждого из них
            составляет 50%.
            <br />
            Это означает, что шансы выпадения орла или решки равны - нет
            предпочтений! <br />
            <br />
            Попробуйте бросить монету и узнайте, выпадет ли на этот раз ваш
            счастливый вариант 🍀🤞
            <br />
            Сколько раз подряд вам удастся угадать? 🎯
            <br />
            <br />
            Нажмите на монету 💰 для броска! 🎯 ➡️
          </p>
        </div>

        <div onClick={changeRandomNum} className="coin" style={rotationStyle}>
          <img src="coin1.png" alt="Лицевая сторона" className="front" />
          <img src="coin2.png" alt="Обратная сторона" className="back" />
        </div>
      </div>
      <p>Попытка: {attempts}</p>
      <p>Результат: {attempts ? (isHeads ? "Решка" : "Орёл") : "Решка"}</p>{" "}
      {/* Исправлено отображение результата */}
      <p className="spacer"></p>
      {attempts > 0 && <h3>Предыдущие результаты:</h3>}
      <ul>
        {previousResults.map((result, index) => (
          <li key={index}>{result}</li>
        ))}
      </ul>
      <p>
        <br />
      </p>
    </div>
  );
};

export default RandomCoin;
