import React, { useState } from "react";

const RandomTicket = ({ randomNumberTicket, rollNumberTicket, victory }) => {
  const [ticketClass, setTicketClass] = useState("ticket ticket-visible"); // начальный класс

  const changeRandomNum = () => {
    // Устанавливаем класс для анимации уезда
    setTicketClass("ticket ticket-exit");

    // Ждем, пока анимация уезда завершится
    setTimeout(() => {
      rollNumberTicket(); // Выполняем бросок для нового номера

      // Устанавливаем класс для появления нового билета
      setTicketClass("ticket ticket-enter");

      // Ждем, пока анимация появления завершится, затем возвращаем к видимому состоянию
      setTimeout(() => {
        setTicketClass("ticket ticket-enter");
      }, 300); // Время на завершение анимации `enter-animation`
    }, 300); // Время на завершение анимации `exit-animation`
  };

  return (
    <div>
      <div className="random">
        <div className="tex">
          <p>
            Счастливый билет 🎫 - это билет, где сумма первых трех цифр равна
            сумме последних трех 🔢 <br /> <br />
            Например, для номера 123321, сумма первых трех (1+2+3) равна сумме
            последних трех (3+2+1), и такой билет считается «счастливым» 🌈{" "}
            <br />
            Вероятность вытянуть счастливый билет составляет примерно 5.5%, что
            делает их весьма редкими. 🔍✨ <br /> <br />
            Испытайте свою удачу 🍀 <br /> Нажмите на билет 🎫 и узнайте, станет
            ли он счастливым! 🎉 ➡️
          </p>
        </div>

        <div
          onClick={changeRandomNum}
          className={`${ticketClass} ${
            victory ? "img-ticket-true" : "img-ticket-false"
          }`}
        >
          <p className="ticketText">{randomNumberTicket}</p>
        </div>
      </div>
      <h2>Результат: {victory ? "Удача!" : "Неудача"}</h2>
      <p>
        <br />
      </p>
    </div>
  );
};

export default RandomTicket;
