import React, { useState } from "react";

const RandomDice = ({ rollDice, previousResults, attempts, randomNum }) => {
  // Создаем состояние для управления визуализацией броска.
  const [show, setShow] = useState(true); // Состояние, показывающее, видно ли изображение кубика.

  // Функция, которая вызывается при клике на кнопку броска кубика.
  const changeRandomNum = () => {
    setShow(false); // Сначала скрываем текущее изображение кубика.

    setTimeout(() => {
      rollDice(); // Вызываем функцию для броска кубика, чтобы сгенерировать новое значение.
      setShow(true); // Показываем новое изображение (кубика).
    }, 100); // Задержка перед показом нового кубика.
  };

  return (
    <div>
      <div className="random">
        <div className="tex">
          <p>
            Игра с кубиком 🎲 - это простой и увлекательный способ проверить
            свою удачу! 🍀
            <br />
            <br />
            Кубик с шестью гранями имеет шесть возможных исходов, от 1 до 6 🔢{" "}
            <br />
            При каждом броске вероятность выпадения каждой грани составляет 1/6
            или примерно 16.67% 📊
            <br />
            <br />
            Попробуйте бросить кубик и посмотрите, какой номер вам выпадет! 🍀✨
            <br />
            <br />
            Нажмите на кубик 🎲 для броска! 🎯 ➡️
          </p>
        </div>
      </div>
      <img
        onClick={changeRandomNum}
        src={`dice${randomNum}.png`} // Используем текущее значение кубика для отображения.
        alt={`Кубик ${randomNum}`}
        className={`dice ${show ? "show" : ""}`}
      />
      <p>Попытка: {attempts}</p> {/* Показываем количество попыток. */}
      <p>Результат: {randomNum}</p> {/* Показываем текущее значение кубика. */}
      <p className="spacer"></p> {/* Просто отступ для внешнего вида. */}
      {attempts > 0 && <h3>Предыдущие результаты:</h3>}{" "}
      {/* Если есть хотя бы одна попытка, показываем заголовок. */}
      <ul>
        {previousResults.map(
          (
            result,
            index // Перебираем массив предыдущих результатов.
          ) => (
            <li key={index}>{result}</li> // Отображаем каждый результат в списке.
          )
        )}
      </ul>
      <p>
        <br />
      </p>
    </div>
  );
};

export default RandomDice;
