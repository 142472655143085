import React, { useState } from "react";
import RandomNumber from "./components/RandomNumber";
import RandomCoin from "./components/RandomCoin";
import RandomDice from "./components/RandomDice";
import RandomTicket from "./components/RandomTicket";
import "./App.css";

function App() {
  const menuItems = [
    { id: 1, label: "Генератор случайных чисел 🔢" },
    { id: 2, label: "Бросить монетку 💰" },
    { id: 3, label: "Бросить кубик 🎲" },
    { id: 4, label: "Cчастливый билет 🎫" },
  ];

  const [activeMenu, setActiveMenu] = useState(menuItems[0].id);

  // ------------------- Числа ----------------------------

  // Функция для генерации случайного числа в заданном диапазоне
  function generateRandomNum(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const [min, setMin] = useState(0);
  const [max, setMax] = useState(100);
  const [count, setCount] = useState(1);
  const [randomNumber, setRandomNumber] = useState([
    generateRandomNum(min, max),
  ]);
  const [checked, setChecked] = useState(true);

  const rollNumber = () => {
    const newRandomNum = new Set();

    while (newRandomNum.size < count) {
      const x = generateRandomNum(min, max);

      if (checked) {
        newRandomNum.add(x); // Set автоматически игнорирует дубликаты
      } else {
        newRandomNum.add(x); // Просто добавляем, дубликаты не важны
      }
    }

    // Преобразуем Set в массив и устанавливаем новое состояние
    setRandomNumber(Array.from(newRandomNum));
  };

  // ------------------- Монета ----------------------------
  const [coinResults, setCoinResults] = useState([]);
  const [attemptsCoin, setAttemptsCoin] = useState(0);

  const [rotation, setRotation] = useState(0);
  const [isHeads, setIsHeads] = useState(0);

  const handleCoinResults = (result) => {
    setCoinResults((prevResults) => [...prevResults, result]);
    setAttemptsCoin((prevAttempts) => prevAttempts + 1);
  };

  const rollCoin = () => {
    const newRandomNum = Math.floor(Math.random() * 2);
    const spins = newRandomNum === 1 ? 3 : 2;
    const newRotation = rotation + spins * 180;
    setIsHeads((newRotation / 180) % 2 === 0);

    setRotation(newRotation);
    handleCoinResults(
      `Попытка ${attemptsCoin + 1}: ${
        (newRotation / 180) % 2 === 0 ? "Решка" : "Орёл"
      }`
    );
  };

  // ------------------- Кубик ----------------------------
  const [diceResults, setDiceResults] = useState([]);
  const [attempts, setAttempts] = useState(0);
  const [randomNum, setRandomNum] = useState(1);

  const handleDiceResults = (result) => {
    setDiceResults((prevResults) => [...prevResults, result]);
    setAttempts((prevAttempts) => prevAttempts + 1);
  };

  const rollDice = () => {
    const newRandomNum = Math.floor(Math.random() * 6) + 1;
    setRandomNum(newRandomNum);
    handleDiceResults(`Попытка ${attempts + 1}: ${newRandomNum}`);
  };

  // ------------------- Cчастливый билет ----------------------------
  const [randomNumberTicket, setRandomNumberTicket] = useState("000001");
  const [victory, setVictory] = useState(false);

  const rollNumberTicket = () => {
    const newRandomNum = String(Math.floor(Math.random() * 1000000)).padStart(
      6,
      "0"
    ); // создаёт строку длиной 6 символов с ведущими нулями

    setRandomNumberTicket(newRandomNum);

    const sumFirstHalf =
      Number(newRandomNum[0]) +
      Number(newRandomNum[1]) +
      Number(newRandomNum[2]);
    const sumSecondHalf =
      Number(newRandomNum[3]) +
      Number(newRandomNum[4]) +
      Number(newRandomNum[5]);

    setVictory(sumFirstHalf === sumSecondHalf);
  };
  // ------------------------------------------------------

  return (
    <>
      <div className="menu">
        <div className="icon-container">
          <img src="icon1.png" alt="Ошибка!" className="img-ico-menu-1" />
          <img src="icon2.png" alt="Ошибка!" className="img-ico-menu-2" />
        </div>
        {window.innerWidth > 480 ? ( // Проверяем ширину экрана
          <>
            {menuItems.map((item) => (
              <h1
                key={item.id}
                className="button-menu"
                onClick={() => setActiveMenu(item.id)}
              >
                {item.label}
              </h1>
            ))}
          </>
        ) : (
          <>
            {
              // Показываем меню только если isOpen true
              <div className="dropdown-content">
                {menuItems.map((item) => (
                  <h1
                    key={item.id}
                    className="button-menu"
                    onClick={() => {
                      setActiveMenu(item.id);
                    }}
                  >
                    {item.label}
                  </h1>
                ))}
              </div>
            }
          </>
        )}
      </div>

      <div className="App">
        {activeMenu === 1 && (
          <RandomNumber
            min={min}
            max={max}
            count={count}
            randomNumber={randomNumber}
            rollNumber={rollNumber}
            setMin={setMin}
            setMax={setMax}
            setCount={setCount}
            checked={checked}
            setChecked={setChecked}
          />
        )}
        {activeMenu === 2 && (
          <RandomCoin
            rollCoin={rollCoin}
            previousResults={coinResults}
            attempts={attemptsCoin}
            rotation={rotation}
            isHeads={isHeads}
          />
        )}
        {activeMenu === 3 && (
          <RandomDice
            rollDice={rollDice}
            previousResults={diceResults}
            attempts={attempts}
            randomNum={randomNum}
          />
        )}
        {activeMenu === 4 && (
          <RandomTicket
            randomNumberTicket={randomNumberTicket}
            rollNumberTicket={rollNumberTicket}
            victory={victory}
          />
        )}
      </div>
    </>
  );
}

export default App;
